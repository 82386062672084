export const yellow = "#FFF6DF";
export const yellow2 = "#FFD56B";
export const secondary = "#00a04a";
export const grey = "#F5F5F5";
export const grey1 = "#4F4F4F";
export const primary = "#023047";
export const blue = "#3B82F6";
export const purple = "#6614B8";
export const violet = "#DBCCFB";
export const green = "#14B8A6";
export const green100 = "#CCFBF1";
export const black = "#121212";
