import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: ["DM Sans", "Biennale"].join(","),
  },
  palette: {
    primary: {
      main: "#008fb6",  //#ff0000   474c86
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTableRow: {
      defaultProps: {
        sx: {
          "&:hover": {
            backgroundColor: "#e6f3f9",
          },
        },
      },
    },
  },
});
