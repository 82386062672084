import {
  Avatar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC, useEffect } from "react";
import { OneToOne as OneToOneModel } from "../../../api/models";
import moment from "moment";
import { getOneToOne } from "../../../api/instructor.service";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StyledTableCell } from "../../../component/StyledTableCell";
import { RootState } from "../../../store";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "../../../hooks/snackbar";
import { Empty } from "../../../component/Empty";
import { getUrl } from "../../../utils/utils";
import { oneToOnePlanning } from "../../../routes/instructor.routes";

const List: FC<{
  list: OneToOneModel[];
}> = (props) => {
  return (
    <TableContainer
      style={{
        borderRadius: 6,
      }}
    >
      <Table
        sx={{
          border: "2px solid #E8E8E8",
        }}
      >
        <TableHead>
          <TableRow
            style={{
              backgroundColor: "#FAFAFA",
            }}
          >
            <TableCell style={{ color: "#048B9A", fontWeight: "bold" }}>
              #
            </TableCell>
            <TableCell style={{ color: "#048B9A", fontWeight: "bold" }}>
              Etudiant
            </TableCell>
            <TableCell style={{ color: "#048B9A", fontWeight: "bold" }}>
              Date
            </TableCell>
            <TableCell style={{ color: "#048B9A", fontWeight: "bold" }}>
              Statut
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.list.map((oneToOne, index) => (
            <TableRow key={oneToOne.id}>
              <StyledTableCell>{index + 1}.</StyledTableCell>
              <StyledTableCell>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    src={getUrl(oneToOne.etudiant.user.userImage)}
                    style={{
                      marginRight: "1rem",
                    }}
                  />
                  {oneToOne.etudiant.user.nomComplet}
                </div>
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: "bold" }}>
                {moment(oneToOne.dateDebut).format("DD MMM YYYY - hh:mm")}
              </StyledTableCell>
              <StyledTableCell>
                {moment(oneToOne.dateDebut).isAfter(moment()) && (
                  <span style={{ color: "#009e4d" }}>À Venir</span>
                )}
                {moment().isBetween(
                  moment(oneToOne.dateDebut),
                  moment(oneToOne.dateDebut).add(15, "minutes")
                ) && (
                  <span
                    style={{
                      color: "#048B9A",
                    }}
                  >
                    En cours
                  </span>
                )}
                {moment(oneToOne.dateDebut)
                  .add(15, "minutes")
                  .isBefore(moment()) && (
                  <span style={{ color: "#707070" }}>Terminée</span>
                )}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const OneToOnePage = () => {
  const userId = useSelector((state: RootState) => state.auth.user!!.id);
  const navigate = useNavigate();
  const query = useQuery({
    queryKey: ["getOneToOneOfInstructor", userId],
    queryFn: () => getOneToOne(userId).then((response) => response.data),
  });
  const snackbar = useSnackbar();

  useEffect(() => {
    if (query.isError) {
      snackbar.show("Erreur lors des chargements des one to ones", "error");
    }
  }, [query.isError]);

  if (query.isLoading) {
    return <></>;
  }

  return (
    <div style={{ margin: "1rem" }}>
      <Button
        variant={"contained"}
        style={{
          backgroundColor: "#023047",
          color: "white",
          padding: "0.5rem 2.5rem",
          marginBottom: "2rem",
        }}
        onClick={() => {
          navigate(oneToOnePlanning);
        }}
      >
        Planifier les One To One
      </Button>
      {query.data && query.data.length > 0 && <List list={query.data} />}
      {query.data?.length === 0 && (
        <Empty message={"Pas de one to one pour le moment"} />
      )}
    </div>
  );
};
